import React from 'react';
import "../../assets/css/style.css";
import "./Footer.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';

function Footer() {
  return (
    <div className='footer'>
      <div className='footer-left-side'>
        <p>DIGIGO GROUP</p>
        <p>© 2022 - Lorem ipsum dolor sit amet - Lorem ipsum -dolor sit- amet · <a href="tel:+1234567890">+00.000000000 · +00.00000000 ·</a></p>
        <div className='footer-icons'>
          <a href="https://www.facebook.com/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faFacebook} />
          </a>
          <a href="https://www.instagram.com/digigogroup/" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faInstagram} />
          </a>
          <a href="https://x.com/?lang=ru" target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon className='footer-icon' icon={faTwitter} />
          </a>
        </div>
      </div>
      <div className='footer-right-side'>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
        <div className='footer-column'>
          <p>TITLE</p>
          <a href="№">PROJECTS</a>
          <a href="№">COLLECTIONS</a>
          <a href="№">CONTACTS</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
