import React from 'react';
import { useNavigate } from 'react-router-dom';
import "./HomePage.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import letuciycelovek from "../../assets/images/letuciycelovek.png";
import partners from "../../assets/images/partners.png";
import projects from "../../assets/images/projects.png";

function HomePage() {
    const navigate = useNavigate(); // Инициализируем useNavigate

    const handleButtonClick = () => {
        navigate('/login'); // Путь к странице логина
    };

    return (
        <>
            <div className='container'>
                <Header />
                <div className='home-page-container'>
                    <div className='better-business-part'>
                        <div className='left-side'>
                            <p><span> Make</span> Your Business <span><br />Better</span></p>
                            <p>It is a long established fact that a reader will be
                                distracted by the readable content of a page when
                                looking at its layout.
                            </p>
                            <button onClick={handleButtonClick}>
                                Get Started
                            </button>
                        </div>
                        <div className='right-side'>
                            <img className='letuciycelovek' src={letuciycelovek} alt="" />
                        </div>
                    </div>
                    <div className='services-home-page'>
                        <p className='header-txt'>Our <span>Services</span></p>
                        <div className='service-wrap'>
                            <div className='service-box'>
                                <p>Software Developer</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                            <div className='service-box'>
                                <p>Network Administration</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                            <div className='service-box'>
                                <p>Python <br />C#</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                            <div className='service-box'>
                                <p>Graphic Design</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                            <div className='service-box'>
                                <p>Website Development</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                            <div className='service-box'>
                                <p>DevOps</p>
                                <p>It is a long established fact that a reader will be</p>
                            </div>
                        </div>
                    </div>
                    <div className='why-us'></div>
                    <div className='partners-home-page'>
                        <p className='header-txt'>Our <span>Partners</span></p>
                        <div className='partners-box'>
                            <img src={partners} alt="Partner 1" />
                            <img src={partners} alt="Partner 2" />
                            <img src={partners} alt="Partner 3" />
                            <img src={partners} alt="Partner 4" />
                            <img src={partners} alt="Partner 5" />
                        </div>
                    </div>
                    <div className='home-page-projects'>
                        <p className='header-txt'>Our <span>Projects</span></p>
                        <div className='projects-box'>
                            <div className='projectinbox1'>
                                <img src={projects} alt="" />
                                <p>Title</p>
                                <p>Body text for whatever you’d like to say.</p>
                            </div>
                            <div className='projectinbox2'>
                                <img src={projects} alt="" />
                                <p>Title</p>
                                <p>Body text for whatever you’d like to say.</p>
                            </div>
                            <div className='projectinbox1'>
                                <img src={projects} alt="" />
                                <p>Title</p>
                                <p>Body text for whatever you’d like to say.</p>
                            </div>
                        </div>
                    </div>
                    <div className='home-page-packages'>
                        <p className='header-txt packages-txt'>Our <span>Packages</span></p>
                        <div className='packages-box'>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button>Button</button>
                            </div>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button className='package2button'>Button</button>
                            </div>
                            <div className='package-item'>
                                <p>Title</p>
                                <p>$50<span>/month</span></p>
                                <ul>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                    <li>List Item</li>
                                </ul>
                                <button>Button</button>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default HomePage;
