import React, { useState } from 'react';
import "./Contact.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot, faPhoneVolume } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    message: "",
  });
  
  const [formStatus, setFormStatus] = useState("");
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.firstName && formData.lastName && formData.email) {
      console.log("Form submitted:", formData);
      setFormStatus("Form successfully submitted!");
      
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        message: "",
      });
    } else {
      setFormStatus("Please fill in all fields.");
    }
  };

  return (
    <>
      <div>
        <Header />
        <div className='contact-container'>
          <div className='contact-header'>
            <p>Contact <span>Us</span></p>
            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
          </div>
          <div className='contact-content'>
            <div className='contact-details'>
              <p>Get In <span>Touch</span></p>
              <p className='contact-description'>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document.</p>
              <div className='contact-info'>
                <div className='contact-item'>
                  <FontAwesomeIcon className='contact-icon' icon={faLocationDot} />
                  <p className='contact-text'>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                </div>
                <div className='contact-item'>
                  <FontAwesomeIcon className='contact-icon' icon={faEnvelope} />
                  <p className='contact-text'>Lorem ipsum is common placeholder text.</p>
                </div>
                <div className='contact-item'>
                  <FontAwesomeIcon className='contact-icon' icon={faPhoneVolume} />
                  <p className='contact-text'>Lorem ipsum is common placeholder text.</p>
                </div>
              </div>
            </div>
            <div className='contact-form-wrapper'>
            <p>Get in <span>Touch</span></p>
              <form className='contact-form' onSubmit={handleSubmit}>
                <label htmlFor="firstName">First Name</label>
                <input
                  className='form-input'
                  type="text"
                  id="firstName"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="lastName">Last Name</label>
                <input
                  className='form-input'
                  type="text"
                  id="lastName"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="email">Email</label>
                <input
                  className='form-input'
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />

                <label htmlFor="message">Message</label>
                <textarea
                  className='form-textarea'
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows="4"
                />

                <button className='form-submit-btn' type="submit">Submit</button>
              </form>

              {formStatus && <p className='form-status-message'>{formStatus}</p>}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Contact;
