import React, { useState } from 'react';
import './LogIn.css'; 
import '../../assets/css/style.css'; 
import Header from '../../components/HeaderComponent/Header';
import Footer from '../../components/FooterComponent/Footer';
import logincomputer from "../../assets/images/logincomputer.png"; 
import axios from 'axios'; 
import { useNavigate } from 'react-router-dom';
function LogIn() {
    
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    const handleLogin = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post('/api/login', { email, password });
           
            localStorage.setItem('token', response.data.token);
            window.location.href = '/dashboard'; 
        } catch (err) {
            setError('Invalid login details');
        } finally {
            setLoading(false);
        }
    };
    const navigate = useNavigate();

    const handleForgotPassword = () => {
        navigate('/reset-password'); 
    };

    return (
        <>
            <div className='container'>
                <Header />
                <div className='log-in-container'>
                    <div className='left-section'>
                        <img src={logincomputer} alt="" className='computer-image' />
                        <div className="business-text">
                            <p><span>Stay</span> Your Business <br /><span>Connected</span></p>
                        </div>
                    </div>
                    <div className='right-section'>
                        <form className='login-form' onSubmit={handleLogin}>
                            {error && <p className="error-message">{error}</p>}
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input
                                    className='form-input'
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>
                            <div className='form-group'>
                                <label htmlFor='password'>Password</label>
                                <input
                                    className='form-input'
                                    type='password'
                                    id='password'
                                    name='password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                            </div>
                            <button type='submit' className='login-btn' disabled={loading}>
                                {loading ? 'Logging in...' : 'Log In'}
                            </button>
                            <button type='button' className='forgot-password-btn' onClick={handleForgotPassword}>Forgot Password?</button>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default LogIn;
