import React from 'react'
import "./About.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
function About() {
    return (
        <>
            <div>
                <Header />
                <div className='about-container'>
                    <div className='about-header'>
                        <p>About <span>Us</span></p>
                        <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                    </div>
                    <div className='about-us-circles'>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>



                    </div>

                    
                    <div className='about-us-circles'>
                        <div className='about-header'>
                            <p>Developer <span>Team</span></p>
                            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>



                    </div>
                    <div className='about-us-circles'>
                        <div className='about-header'>
                            <p>Designer <span>Team</span></p>
                            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>

                        <div className="row">
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                            <div className='circle-box'>
                                <div className="circle-image">
                                    <img src="your-image-url1.jpg" alt="" />

                                </div>
                                <p>NAME.SN</p>
                                <p>Position</p>
                            </div>
                        </div>



                    </div>

                </div>
                <Footer />

            </div>

        </>
    )
}

export default About
