import React from 'react';
import './Projects.css';
import '../../assets/css/style.css';
import Header from '../../components/HeaderComponent/Header';
import Footer from '../../components/FooterComponent/Footer';
import phonemochup from "../../assets/images/Phone Mockup.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
function Projects() {
    return (
        <>
            <div className='container'>
                <Header />
                <div className='projects-container'>
                    <div className='project-header'>
                        <p>Our <span>Projects</span></p>
                        <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={phonemochup} alt="" />
                        </div>
                        <div>
                            <p>Headline</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.</p>
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>


                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={phonemochup} alt="" />
                        </div>
                        <div>
                            <p>Headline</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.</p>
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>


                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={phonemochup} alt="" />
                        </div>
                        <div>
                            <p>Headline</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.</p>
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>


                    </div>
                    <div className='projects-headline'>
                        <div>
                            <img src={phonemochup} alt="" />
                        </div>
                        <div>
                            <p>Headline</p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Condimentum diam orci pretium a pharetra, feugiat cursus. Dictumst risus, sem egestas odio cras adipiscing vulputate. Nisi, risus in suscipit non. Non commodo volutpat, pharetra, vel.</p>
                            <button>Get Started <FontAwesomeIcon icon={faArrowRight} /></button>
                        </div>


                    </div>

                </div>

                <Footer />
            </div>


        </>
    )
}

export default Projects
