import React, { useState } from 'react';
import './Reset.css';
import '../../assets/css/style.css';
import Header from '../../components/HeaderComponent/Header';
import Footer from '../../components/FooterComponent/Footer';
import logincomputer from "../../assets/images/logincomputer.png";
import axios from 'axios';

function Reset() {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');


    const handleCancel = () => {
        window.location.href = '/loginpage'; 
    };

   
    const handleResetPassword = async (event) => {
        event.preventDefault();
        setMessage('');
        setError('');

        try {
             await axios.post('/api/reset-password', { email });
            setMessage('A password reset link has been sent to your email.');
        } catch (err) {
            setError('Error resetting password. Please try again.');
        }
    };

    return (
        <>
            <div className='container'>
                <Header />
                <div className='log-in-container'>
                    <div className='left-section'>
                        <img src={logincomputer} alt="Computer" className='computer-image' />
                        <div className="business-text">
                            <p><span>Stay</span> Your business <br /><span>Connected</span></p>
                        </div>
                    </div>
                    <div className='right-section'>
                        <form className='login-form' onSubmit={handleResetPassword}>
                            <div className='form-group'>
                                <label htmlFor='email'>Email</label>
                                <input
                                    className='form-input'
                                    type='email'
                                    id='email'
                                    name='email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            {message && <p className="success-message">{message}</p>}
                            {error && <p className="error-message">{error}</p>}

                            <div>
                                <button type='button' className='cencel-btn' onClick={handleCancel}>Cancel</button>
                                <button type='submit' className='reset-password-btn'>Reset Password</button>
                            </div>
                        </form>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}

export default Reset;
