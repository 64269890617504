import React from 'react';
import "./Services.css";
import "../../assets/css/style.css";
import Header from "../../components/HeaderComponent/Header";
import Footer from "../../components/FooterComponent/Footer";
import workflowimg from "../../assets/images/workflow.png";
import bettercomponent from "../../assets/images/bettercomponents.png";
import flexibility from "../../assets/images/flexibility.png";
import layouts from "../../assets/images/layouts.png";
import organazed from "../../assets/images/organazed.png";
import userfriendly from "../../assets/images/userfriendly.png";
function Services() {
  return (
    <>
      <div>
        <Header />
        <div className='services-container'>
          <div className='services-header'>
            <p>Our <span>Services</span></p>
            <p>Lorem ipsum is common placeholder text used to demonstrate the graphic elements of a document or visual presentation.</p>
          </div>
          <div className="services-content">
            <div className='service-row'>
              <div className='service-item'>
                <img src={workflowimg} alt="" />
                <p >Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>
              <div className='service-item'>
                <img src={flexibility} alt="" />
                <p>Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>
              <div className='service-item'>
                <img src={userfriendly} alt="" />
                <p>Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>

            </div>
            <div className='service-row'>
              <div className='service-item'>
                <img src={layouts} alt="" />
                <p>Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>
              <div className='service-item'>
                <img src={bettercomponent} alt="" />
                <p>Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>
              <div className='service-item'>
                <img src={organazed} alt="" />
                <p>Robust workflow</p>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed erat nibh tristique ipsum.</p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default Services;
